/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, TitleMain, Divider, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Praktické informace"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pb--80 pt--80" name={"2trph0wn22z"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--1" content={"PRAKTICKÉ INFORMACE"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"informace"} style={{"marginTop":0,"paddingTop":0}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":976,"paddingLeft":0,"paddingRight":0}} columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <TitleMain className="title-box ff--1 fs--24" content={"<span style=\"color: var(--black);\">PREVENCE</span>"}>
              </TitleMain>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left ff--1 fs--18" content={"Nárok od pojišťovny<span style=\"font-weight: normal;\"> na preventivní prohlídku má každý pacient jednou za 2 roky.<br>Provádíme předem odběry a vyšetření moči, k prohlídce jste přizváni na vybraný čas. Vyšetření obsahuje informování o výsledcích krevních testů a následné poradenství, měření krevního tlaku, vyšetření očí pomocí optotypů, kontrola výšky a hmotnosti, tělesná prohlídka ve spodním prádle, dále kontrola očkování a dle věku a rizik doplnění dalších vyšetření (EKG, test na skryté krvácení ve stolici, mammograf,...).</span><br>"}>
              </Title>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":976,"paddingLeft":0,"paddingRight":0}} columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <TitleMain className="title-box ff--1 fs--24" content={"<span style=\"color: rgb(0, 0, 0);\">ODBĚRY</span><br>"}>
              </TitleMain>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left ff--1 fs--18" content={"<span style=\"font-weight: normal;\">Provádíme 7:00-8:00 úterý, čtvrtek, pátek. vždy po předchozí domluvě. na odběry (poud není jiná domluva) je nutné přijít nalačno, poslední jídlo cca v 19:00, ráno se dostatečně napít vody (nic sladkého).<br>Před odběry glykemie („cukr“) a cholesterolu je vhodná dieta s vyloučením sladkostí a tučných jídel, alkoholu 3 dny předem. Vaše obvyklé léky užijte dle zvyklostí.</span><br>"}>
              </Title>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":976,"paddingLeft":0,"paddingRight":0}} columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <TitleMain className="title-box ff--1 fs--24" content={"<span style=\"color: rgb(0, 0, 0);\">OČKOVÁNÍ</span><br>"}>
              </TitleMain>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left ff--1 fs--18" content={"<span style=\"font-weight: normal;\">Očkování lze provádět pouze u zdravých pacientů- tedy bez akutního horečnatého nachlazení. Zmiňovaná očkování se aplikují do paže- do svalu či podkožně.</span><br><br>Po aplikaci je nutné vyčkat v čekárně cca 30 minut<span style=\"font-weight: normal;\">- kvůli pozorování akutní reakce po očkování. Místo vpichiü může být několik dní citlivé, večer není vyjímečné zvýšení tělesné teploty</span><br><br>Tetanus <span style=\"font-weight: normal;\">- vakcíny máme skladem, pacienti do 60 let se očkují 1x za 15 let, nad 60 let 1x za 10 let.</span><br>Prevenar <span style=\"font-weight: normal;\">- proti pneumokokům, na objednání. U pacientů nad 65 let hradí pojišťovna . Blíže v ordinaci.</span><br>Chřipka <span style=\"font-weight: normal;\">- očkujeme vždy cca od poloviny září do konce prosince. U pacientů nad 65 let hradí pojišťovna.</span><br>Klíšťovka <span style=\"font-weight: normal;\">- očkujeme především vakcínou FSME, předpis na recept.<br><br>O možnostech dalšího očkování se poraďte přímo v ordinaci.</span><br>"}>
              </Title>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":976,"paddingLeft":0,"paddingRight":0}} columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <TitleMain className="title-box ff--1 fs--24" content={"<span style=\"color: rgb(0, 0, 0);\">LÉKAŘSKÁ SLUŽBA PRVNÍ POMOCI</span><br>"}>
              </TitleMain>

              <Subtitle className="subtitle-box ff--1 fs--24" content={"<span style=\"color: rgb(0, 0, 0); font-weight: normal;\">MIMO ORDINAČNÍ HODINY</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left ff--1 fs--18" content={"Provoz ordinace na Ponávce<span style=\"font-weight: normal;\"> - Úrazová nemocnice : 17:00-7:00<br>O víkendech a svátcích: </span>celodenně<br><span style=\"font-weight: normal;\">Přístup na Lékařskou pohotovostní službu praktického lékaře i LPS zubní je novým vstupem mezi budovami Ponávka 6 a Ponávka 10 a dále přes dvůr do budovy Koliště 41, 1.patro.</span><br><span style=\"font-weight: normal;\">Telefon: 545 538 538</span><br><br>Zubní pohotovost<br><span style=\"font-weight: normal;\">Telefon: 545 538 421</span><br>Pracovní doba zubní LPS<span style=\"font-weight: normal;\">: pracovní dny: 18:00 - 24:00 volné dny: 8:00 - 20:00&nbsp;</span><br>"}>
              </Title>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":976,"paddingLeft":0,"paddingRight":0}} columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <TitleMain className="title-box ff--1 fs--24" content={"<span style=\"color: rgb(0, 0, 0);\">TOXIKOLOGICKÉ INFORMAČNÍ STŘEDISKO</span><br>"}>
              </TitleMain>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left ff--1 fs--18" content={"<span style=\"font-weight: normal;\">Konzultace je možná nepřetržitě, po pozření jedovaté či jinak nebezpečné látky: </span>Laické i lékařské dotazy na akutní intoxikace se řeší výhradně na přímých telefonních linkách TIS<span style=\"font-weight: normal;\">: </span><br><span style=\"font-weight: normal;\">+224 91 92 93 a +224 91 54 02</span><br><br>E-recept<span style=\"font-weight: normal;\">: možnost odeslání receptu do Vašeho telefonu formou sms nebo na e-mail</span><br>Prevence<span style=\"font-weight: normal;\">: každý pacient 1x za 2 roky. Dle věku doplnění EKG, mamografu, odeslání k urologovi, test na skryté krvácení apod.</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}